import * as React from 'react'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import { Layout } from '../components'

const Wrapper = styled.section`
  ${tw`w-full relative flex flex-col justify-center items-center`};
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23323d59' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
    #273046;
  height: 100vh;
`

const Title = tw.h1`text-epvp-color font-sans text-4xl md:text-5xl mb-4 mt-0`

const SubTitle = tw.p`text-white font-sans font-thin mt-2 text-lg md:text-xl`

const NotFound = () => (
  <Layout>
    <Wrapper>
      <Title>Not Found</Title>
      <SubTitle>
        The page you searched for doesn't exist.{' '}
        <Link tw="text-epvp-color" to="/">
          Want to go home?
        </Link>
      </SubTitle>
    </Wrapper>
  </Layout>
)

export default NotFound
